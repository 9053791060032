import { ValueOf } from '@powerednow/type-definitions';
import { unitOfTime, HTML5_FMT } from 'moment';
import momentOverride from '@powerednow/shared/modules/utilities/momentOverride';
import { APPOINTMENT_DURATION } from '@powerednow/shared/constants/action';

import moment from 'moment-timezone';

const cityTimezones = require('city-timezones');
const countriesAndTimezones = require('countries-and-timezones').default;

const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const MAXIMUM_UNAVAILABLE_DATE_TO_SHOW = 3;

export type DayPartKey = 'am' | 'pm' | 'ev' | 'allDay' | 'allDayWorkingHours';
export type HourNumber = 0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23;

export type DayPart = {
    key: DayPartKey,
    shortName: string,
    name: string,
    startHour: number,
    endHour: HourNumber,
    appointmentDurationId: number
}

const DAY_PARTS:DayPart[] = [
    {
        key: 'am',
        shortName: 'am.',
        name: 'Morning',
        startHour: 0,
        endHour: 11,
        appointmentDurationId: APPOINTMENT_DURATION.MORNING,
    },
    {
        key: 'pm',
        shortName: 'pm.',
        name: 'Afternoon',
        startHour: 12,
        endHour: 17,
        appointmentDurationId: APPOINTMENT_DURATION.AFTERNOON,
    },
    {
        key: 'ev',
        shortName: 'eve.',
        name: 'Evening',
        startHour: 18,
        endHour: 23,
        appointmentDurationId: APPOINTMENT_DURATION.EVENING,
    },
    {
        key: 'allDay',
        shortName: 'allDay',
        name: 'Whole day',
        startHour: 0,
        endHour: 23,
        appointmentDurationId: APPOINTMENT_DURATION.ALL_DAY,
    },
    {
        key: 'allDayWorkingHours',
        shortName: 'allDayWorkingHours',
        name: 'All day - Working hours',
        startHour: 9,
        endHour: 17,
        appointmentDurationId: APPOINTMENT_DURATION.WORKING_DAYS,
    },
];

const DAY_SHORT_NAMES = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
];

export const patterns = {
    ISO8601LongMoment: `${HTML5_FMT.DATE} ${HTML5_FMT.TIME_SECONDS}`,
    ISO8601Long: 'Y-MM-DD HH:mm:ss',
    ISO8601Short: 'Y-MM-DD',
    ShortDate: 'D/M/Y',
    LongDate: 'l, F D, Y',
    FullDateTime: 'l, F D, Y hh:mm:ss A',
    MonthDay: 'F D',
    ShortTime: 'h:m A',
    LongTime: 'h:m:s A',
    SortableDateTime: 'Y-M-d\\TH:m:s',
    UniversalSortableDateTime: 'Y-MM-MM HH:mm:ss',
    YearMonth: 'F, Y',
    LongNamedDateTime: 'l dS M y H:i',
    LongNamedDate: 'l dS M y',
    MonthDayTime: 'F D H:m',
    ukDateTime: 'D-M-Y HH:mm',
    ukDateWithAt: 'Y/MM/DD [at] HH:mm',
    messageDateTime: 'Y/MM/DD HH:mm',
    messageDateTimeShortDate: 'DD/MM/YY HH:mm',
    shortDateWithTime: 'DD/MM/YY HH:mm',
    LongDateOnly: 'DD/MM/YYYY',
    shortDateOnly: 'DD/MM/YY',
} as const;

export const DAY_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

type Inclusivity = '()' | '[)' | '(]' | '[]';

export function getPrecedingPeriod({
    date,
    amount = 1,
    unit = 'months',
    inputFormat = 'YYYY-MM',
    outputFormat = patterns.ISO8601LongMoment,
}) {
    const startDate = moment(date, inputFormat).startOf(unit as unitOfTime.StartOf).format(outputFormat);
    const endDate = moment(startDate)
        .add(amount, unit as unitOfTime.DurationConstructor)
        .format(outputFormat);
    return { startDate, endDate };
}

export function getNameOfMonth(month) {
    if (month < 1 || month > 12) {
        throw new Error('Month parameter should be between 1 and 12');
    }

    return MONTH_NAMES[month - 1];
}

export function getDayNamesSundayFirst() {
    const daysCopy = [...DAY_NAMES];
    daysCopy.unshift(daysCopy.pop()!);
    return daysCopy;
}

export function getDayName(day) {
    return getDayNamesSundayFirst()[day.getDay()];
}

export function getDayNamesBetweenDates(startDate: Date, endDate: Date): string[] {
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    const startDayIndex = momentStartDate.isoWeekday() - 1;
    const endDayIndex = momentEndDate.isoWeekday() - 1;

    const numberOfDays: number = momentEndDate.startOf('day').diff(momentStartDate.startOf('day'), 'days') + 1;

    if (numberOfDays === 1) {
        return [DAY_NAMES[startDayIndex]];
    }

    if (DAY_NAMES.length <= numberOfDays) {
        return [...DAY_NAMES];
    }

    if (startDayIndex > endDayIndex) {
        return [...DAY_NAMES.slice(startDayIndex, DAY_NAMES.length), ...DAY_NAMES.slice(0, endDayIndex + 1)];
    }

    return DAY_NAMES.slice(startDayIndex, Math.min(numberOfDays, DAY_NAMES.length) + startDayIndex);
}

export function getUnavailableDatesBetweenDates(startDate: Date, endDate: Date, unavailableDays: string[]) {
    const dateArray: string[] = [];
    const stopDate = moment(endDate);
    let currentDate = moment(startDate);

    while (currentDate <= stopDate && dateArray.length < MAXIMUM_UNAVAILABLE_DATE_TO_SHOW) {
        const dayName = currentDate.format('dddd');
        if (unavailableDays.includes(dayName)) {
            dateArray.push(currentDate.format(patterns.shortDateOnly));
        }
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

export function getDayNames() {
    return DAY_NAMES;
}

export function getShortDayNames() {
    return DAY_SHORT_NAMES;
}

export function getShortDayNamesSundayFirst() {
    const daysCopy = [...DAY_SHORT_NAMES];
    daysCopy.unshift(daysCopy.pop()!);
    return daysCopy;
}

export function isDateBetween(
    {
        date = undefined,
        startDate = null,
        endDate = null,
        unit = null,
        inclusivity = undefined,
    }:{
        date?: Date,
        startDate?: Date | string | null,
        endDate?: Date | string | null,
        unit?: unitOfTime.StartOf,
        inclusivity?: Inclusivity,
    } = {},
) {
    return moment(date).isBetween(startDate, endDate, unit, inclusivity);
}

export function getNowDate() {
    return moment().utc().toDate();
}

export function getNow(): string {
    const date = moment().utc();
    return date.format(patterns.ISO8601LongMoment);
}

export function isDateInFuture(date) {
    const givenDateInSeconds = moment(date)
        .utc();
    const now = moment().utc();
    return givenDateInSeconds.isAfter(now);
}

export function isSameMonth(date1, date2) {
    if (date1 && date2) {
        return moment(date1)
            .format('YYYYMM') === moment(date2)
            .format('YYYYMM');
    }
    return false;
}

export function hasCommonRange(range1Start, range1End, range2Start, range2End) {
    return !(range2Start > range1End || range2End < range1Start);
}

export function getStartOfDay(date) {
    const newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    // just to match the test
    newDate.setMilliseconds(0);

    return newDate;
}
export function getEndOfDay(date) {
    const newDate = new Date(date);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    // just to match the test
    newDate.setMilliseconds(0);

    return newDate;
}

export function getFirstDayOfTheMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDayOfTheMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getFirstDayOfTheWeek(date, firstDay = 'monday') {
    const day = date.getDay();
    let diff = day; // find saturday

    if (firstDay === 'monday') { // find monday
        diff = day === 0 ? 6 : (day - 1);
    }

    return new Date(date.setDate(date.getDate() - diff));
}

export function getLastDayOfTheWeek(date, firstDay = 'monday') {
    const day = date.getDay();
    let diff = 6 - day; // find saturday

    if (firstDay === 'monday') { // find sunday
        diff = day === 0 ? 0 : (7 - day);
    }

    return new Date(date.setDate(date.getDate() + diff));
}

/**
 * Use it with caution!
 * It operates slightly differently compared
 * to functions like addMonth or addMinutes
 * and it utilizes momentOverride.
 */
export function addDays(daysToAdd, date = new Date()) {
    return momentOverride(date).add(daysToAdd, 'days');
}

export function addMonth(date, diff) {
    const modifiedDate = new Date(date);
    modifiedDate.setMonth(date.getMonth() + diff);
    return modifiedDate;
}

export function addMinutes(date, diff, dateFormat = patterns.ISO8601Long) {
    const modifiedDate = moment(date);
    modifiedDate.add('minutes', diff);
    return modifiedDate.format(dateFormat);
}

export function addSeconds(date: Date | string, seconds: number) {
    const momentDate = moment(date);
    momentDate.add('seconds', seconds);
    return momentDate.toDate();
}

export function addMilliSeconds(date: Date | string, milliseconds: number) {
    const momentDate = moment(date);
    momentDate.add('milliseconds', milliseconds);
    return momentDate.toDate();
}

export function duration(date1, date2) {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    return moment.duration(momentDate2.diff(momentDate1)).asMilliseconds();
}

export function dateDifference(date1, date2, unit: unitOfTime.Diff = 'days') {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);

    const dayDifference = momentDate2.diff(momentDate1, unit);

    return dayDifference;
}

export function isSpan(date1, date2) {
    const date1AsMs = Number(date1);
    const date2AsMs = Number(date2);
    const nextDayStart = ((date1AsMs + 86400000) - (date1AsMs % 86400000));
    return (nextDayStart - date1AsMs) < (date2AsMs - date1AsMs) || date2AsMs > nextDayStart;
}

// It will not work for greater than 24hours.
export function calculateUTCTimeOffset(offset) {
    const directionMap = {
        0: '+',
        1: '+',
        '-1': '-',
    };
    const direction = directionMap[Math.sign(offset)];

    const lengthOfTime = moment.duration(Math.abs(offset), 'minutes');
    const minutes = lengthOfTime.minutes().toString();
    const hours = lengthOfTime.hours().toString();
    return `UTC${direction}${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
}

/**
 * get rounded date to the nearest NEXT snap in minutes.
 *
 * @param snap in minutes
 * @param date
 * @returns {Date}
 */
export function getNextSnap(date, snap = 15) {
    const snappedDate = new Date(date);
    const minutes = snappedDate.getMinutes();
    let intervals = Math.floor(minutes / snap);

    if (minutes % snap !== 0) {
        intervals += 1;
    }

    if (intervals === 60 / snap) {
        snappedDate.setHours(snappedDate.getHours() + 1);
        intervals = 0;
    }
    snappedDate.setMinutes(intervals * snap);
    snappedDate.setSeconds(0);
    return snappedDate;
}

export function getPreviousSnap(date: Date, snap = 15): Date {
    const snappedDate = new Date(date);
    const minutes = snappedDate.getMinutes();
    let intervals = Math.floor(minutes / snap);

    if (minutes % snap === 0) {
        intervals -= 1;
    }

    if (intervals === 60 / snap) {
        snappedDate.setHours(snappedDate.getHours() - 1);
        intervals = 0;
    }
    snappedDate.setMinutes(intervals * snap);
    snappedDate.setSeconds(0);
    return snappedDate;
}

export function _getTimezoneFromCityOrCountryCode(city, countryCode) {
    if (city) {
        const cityLookupResults = cityTimezones.lookupViaCity(city);
        const cityLookup = cityLookupResults.find(({ iso2 }) => iso2 === countryCode);
        if (cityLookup) {
            return cityLookup.timezone;
        }
    }

    const defaultTimezone = 'Europe/London';
    const timezonesByCountryCode = countriesAndTimezones.getTimezonesForCountry(countryCode);
    if (!timezonesByCountryCode) {
        return defaultTimezone;
    }
    const [timezoneByCountryCode] = timezonesByCountryCode;
    return timezoneByCountryCode ? timezoneByCountryCode.name : defaultTimezone;
}

export function calculateLocalDateTime(utcDateTime, city, countryCode) {
    const timezone = _getTimezoneFromCityOrCountryCode(city, countryCode);
    return moment(utcDateTime).tz(timezone).format(patterns.ISO8601LongMoment);
}

export function format(utcDateTime, pattern = 'ISO8601LongMoment') {
    const momentDate = moment(utcDateTime);
    return momentDate.isValid() ? momentDate.format(patterns[pattern] || pattern) : undefined;
}

export function formatDateToHoursAndMinutes(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export function getMinutesInHoursAndMinutes(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: number | string = Math.floor(sec / 3600); // get hours
    let minutes: number | string = Math.floor((sec - (hours * 3600)) / 60); // get minutes

    if (hours < 10) {
        hours = `0${hours}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
}

export function parse(input, desiredFormat?): Date {
    return moment(input, desiredFormat).toDate();
}

export function isOnSameDay(date1, date2) {
    if (date1 && date2) {
        return parse(date1).toDateString() === parse(date2).toDateString();
    }
    return false;
}

export function getPartOfDay(key) {
    return DAY_PARTS.find(dayPart => dayPart.key === key);
}

export function getPartOfDayByKey(key) {
    const partOfDay = getPartOfDay(key);
    return partOfDay || null;
}

export function getDayPartByAppointmentDuration(appointmentDurationId: number) {
    const dayPart = DAY_PARTS.find(dp => dp.appointmentDurationId === appointmentDurationId);
    return dayPart || null;
}

export function getDayPartInterval(date, dayPart) {
    const dayPartObject = getPartOfDayByKey(dayPart);

    if (!dayPartObject) {
        return date;
    }

    const start = new Date(date);
    const end = new Date(date);

    start.setHours(dayPartObject.startHour);
    end.setHours(dayPartObject.endHour);

    return { start, end };
}

export function getPartOfDayNameByKey(key) {
    const partOfDay = getPartOfDay(key);
    return partOfDay ? partOfDay.name : '';
}

export function getPartsOfDay(): DayPart[]
export function getPartsOfDay(_style:'array'): DayPart[]
export function getPartsOfDay(_style:'keys'): DayPartKey[]
export function getPartsOfDay(_style:'shortNames'|'names'): string[]
export function getPartsOfDay(style = 'array') {
    if (style === 'shortNames') {
        return DAY_PARTS.map(dayPart => dayPart.shortName);
    }

    if (style === 'keys') {
        return DAY_PARTS.map(dayPart => dayPart.key);
    }

    if (style === 'names') {
        return DAY_PARTS.map(dayPart => dayPart.name);
    }

    return DAY_PARTS.filter(dayPart => dayPart.key !== 'allDay' && dayPart.key !== 'allDayWorkingHours');
}

export function getDateStringFromTimestamp(timestamp) {
    if (!timestamp) {
        return undefined;
    }
    /**
     * We used to have const date = new Date() there. But we need the timezoneOffset of
     * the date when we want to create the appointment, not to current one.
     *
     * @type {Date}
     */
    const date = new Date(parseInt(timestamp, 10));
    const utcDate = new Date(parseInt(timestamp, 10) + (date.getTimezoneOffset() * 60000));

    return format(utcDate, patterns.ISO8601Long);
}

export function convertToLocal(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function convertToLocalFromDateString(dateString: string | Date) {
    const date = new Date(dateString);
    return convertToLocal(date);
}

export function isValidDate(d) {
    return d instanceof Date && isFinite(Number(d));
}

export function isValid(date, formatOfDate?) {
    if (date instanceof Date) {
        return isValidDate(date);
    }
    if (date === '0000-00-00 00:00:00') {
        return false;
    }

    const parsedDate = parse(date, formatOfDate);

    return date && isValidDate(parsedDate);
}

export function convertTimeToDate(timeString) {
    return parse(timeString, 'HH:mm');
}

export function addPlusHours(relatedHour, plusHour) {
    return moment(relatedHour, 'HH:mm').add(plusHour, 'hours').format('HH:mm');
}

export function getTimeParts(time) {
    const elements = time.split(':');
    const hour = parseInt(elements[0], 10);
    const minutes = parseInt(elements[1], 10);
    const fullMinutes = hour * 60 + minutes;

    return {
        hour, minutes, fullMinutes,
    };
}

export function isAfter(start, end) {
    const startMoment = moment(start);
    const endMoment = moment(end);
    return startMoment.isAfter(endMoment);
}

export function isSameOrAfter(start, end) {
    const startMoment = moment(start);
    const endMoment = moment(end);
    return startMoment.isSameOrAfter(endMoment);
}

/**
 *
 * @param start date
 * @param end date
 * @param maxDuration milliseconds
 */
export function isInDuration(start, end, maxDuration) {
    const millisecs = duration(start, end);
    return millisecs <= maxDuration;
}

/**
 *
 * @param start date
 * @param end date
 * @param maxDuration milliseconds
 */
export function isOutOfDuration(start, end, maxDuration) {
    const millisecs = duration(start, end);
    return millisecs >= maxDuration;
}

export function formatToLocalDate(date: Date, pattern: ValueOf<typeof patterns> = patterns.LongDateOnly) {
    if (!isValid(date)) {
        return '';
    }
    const finalDate = convertToLocal(date);

    return format(finalDate, pattern);
}
