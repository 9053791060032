import {
    atom, selector, selectorFamily,
} from 'recoil';
import SETTINGS_KEYS from '@powerednow/shared/constants/settingsKeys';
import { ValueOf } from '@powerednow/type-definitions';
import { authState, dataWrapper } from '@data/state/auth';
import converterUtils from '@powerednow/shared/modules/utilities/converter';
import { complexFindModel } from '@powerednow/models/modelLookup';

const companySettings = selectorFamily({
    key: 'companySettings',
    get: (settingsKey: ValueOf<typeof SETTINGS_KEYS>) => async ({ get }) => {
        const authData = get(authState);
        const { data: { companyId } } = authData;
        const dataWrapperInstance = get(dataWrapper);
        const complexCompany = await dataWrapperInstance.getComplexDataObject(complexFindModel('Company'), companyId!);
        return complexCompany.getSettingValue(settingsKey);
    },
});

const portalEnabled = atom<boolean>({
    key: 'companySettings/portalEnabled',
    default: selector({
        key: 'companySettings/portalEnabled/Default',
        get: async ({ get }) => {
            const authData = get(authState);
            if (!authData.data?.companyId) {
                return false;
            }
            return converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.PORTAL_ENABLED)));
        },
    }),
});

const displayProjectsOnPortal = atom<boolean>({
    key: 'companySettings/displayProjectsOnPortal',
    default: selector({
        key: 'companySettings/displayProjectsOnPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.DISPLAY_PROJECTS_ON_PORTAL))),
    }),
});

const canSiteContactsAccessFullPortal = atom<boolean>({
    key: 'companySettings/canSiteContactsAccessFullPortal',
    default: selector({
        key: 'companySettings/canSiteContactsAccessFullPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.CAN_SITE_CONTACTS_ACCESS_FULL_PORTAL))),
    }),
});

const canCustomerAccessPortalChat = atom<boolean>({
    key: 'companySettings/canCustomerAccessPortalChat',
    default: selector({
        key: 'companySettings/canCustomerAccessPortalChat/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.CAN_CUSTOMER_ACCESS_PORTAL_CHAT))),
    }),
});

const displayProjectFilesTabInThePortal = atom<boolean>({
    key: 'companySettings/displayProjectFilesTabInThePortal',
    default: selector({
        key: 'companySettings/displayProjectFilesTabInThePortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.DISPLAY_FILES_ON_PORTAL))),
    }),
});

const displayAssigneePhoneNumberOnPortal = atom<boolean>({
    key: 'companySettings/displayAssigneePhoneNumberOnPortal',
    default: selector({
        key: 'companySettings/displayAssigneePhoneNumberOnPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.DISPLAY_ASSIGNEE_PHONE_NUMBER_ON_PORTAL))),
    }),
});

const displayCompanyPhoneNumberOnPortal = atom<boolean>({
    key: 'companySettings/displayCompanyPhoneNumberOnPortal',
    default: selector({
        key: 'companySettings/displayCompanyPhoneNumberOnPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.DISPLAY_COMPANY_PHONE_NUMBER_ON_PORTAL))),
    }),
});

export {
    companySettings,
    displayProjectsOnPortal,
    canSiteContactsAccessFullPortal,
    canCustomerAccessPortalChat,
    displayProjectFilesTabInThePortal,
    displayAssigneePhoneNumberOnPortal,
    displayCompanyPhoneNumberOnPortal,
    portalEnabled,
};
