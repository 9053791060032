import { selector, selectorFamily } from 'recoil';
import { authState, dataWrapper } from '@data/state/auth';
import { ValueOf } from '@powerednow/type-definitions';
import { FEATURES } from '@powerednow/shared/constants/subscription';
import { complexFindModel } from '@powerednow/models/modelLookup';
import { canSiteContactsAccessFullPortal, portalEnabled } from '@data/state/companySettings';
import CONTACT_TO_ADDRESS from '@powerednow/shared/constants/contactToAddress';
import ACCOUNT_TYPE from '@powerednow/shared/constants/accountType';

export const canUseFeature = selectorFamily<boolean, ValueOf<typeof FEATURES>>({
    key: 'canUseFeature',
    get: featureId => async ({ get }) => {
        const authData = get(authState);
        const { data: { companyId } } = authData;
        if (!companyId) {
            return false;
        }
        const dataWrapperInstance = get(dataWrapper);
        const complexCompany = await dataWrapperInstance.getComplexDataObject(complexFindModel('Company'), companyId!);
        //
        // Make sure all PurchaseSetups are loaded to avoid individual requests
        //
        await complexFindModel('PurchaseSetup').getAll(complexCompany);
        const subInfo = await complexCompany.getSubscriptionInfo();
        return subInfo.isSubscriptionAllows(featureId);
    },
});

export const isSiteContact = selector<boolean>({
    key: 'isSiteContact',
    get: async ({ get }) => {
        const authData = get(authState);
        const { data: { contactId, customerId } } = authData;

        if (!contactId) {
            return false;
        }

        const dataWrapperInstance = get(dataWrapper);
        const complexCustomer = await dataWrapperInstance.getComplexDataObject(complexFindModel('Customer'), customerId!);
        const contactToAddress = await complexCustomer.getSiteByContactId(contactId);

        return contactToAddress?.data.type === CONTACT_TO_ADDRESS.TYPE.SITE;
    },
});

export const getAccountType = selector<number>({
    key: 'getAccountType',
    get: async ({ get }) => {
        const authData = get(authState);
        const { data } = authData;
        
        if (!data?.email) {
            return ACCOUNT_TYPE.V8_CUSTOMERS; // Old v8 customers
        }
        
        if (data?.userId) {
            return ACCOUNT_TYPE.USER; // App user with view online button
        }

        if (get(isSiteContact)) {
            return ACCOUNT_TYPE.SITE_CONTACT;
        }
        return ACCOUNT_TYPE.CUSTOMER; // logged in Customer
    },
});

export const isPortalAvailable = selector<boolean>({
    key: 'isPortalAvailable',
    get: async ({ get }) => {
        const authData = get(authState);
        const { data: { used } } = authData;
        const isPortalAllowed = get(canUseFeature(FEATURES.CAN_USE_PORTAL));
        return isPortalAllowed && used !== true && get(portalEnabled);
    },
});

export const hasFullPortalAccess = selector<boolean>({
    key: 'hasFullPortalAccess',
    get: async ({ get }) => {
        if (!get(isPortalAvailable)) {
            return false;
        }
        const accountType = get(getAccountType);

        if (accountType === ACCOUNT_TYPE.SITE_CONTACT) {
            return get(canSiteContactsAccessFullPortal);
        }

        if (accountType === ACCOUNT_TYPE.CUSTOMER) {
            return true;
        }

        return false;
    },
});

export const isSiteContactAndHasFullPortalPermission = selector<boolean>({
    key: 'isSiteContactAndHasFullPortalPermission',
    get: async ({ get }) => {
        const isSiteContactBool = get(isSiteContact);
        return isSiteContactBool ? get(canSiteContactsAccessFullPortal) : true;
    },
});

export const canUsePortalFeature = selector<boolean>({
    key: 'canUsePortalFeature',
    get: async ({ get }) => get(canUseFeature(FEATURES.CAN_USE_PORTAL)),
});
