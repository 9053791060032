import { jsx as _jsx } from "react/jsx-runtime";
import { createTheme, darken } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { Done, DoneOutline } from '@mui/icons-material';
import { lighten } from '@mui/material';
import { lightThemeSource } from '../Default/stylesheet';
import PowerednowRadio from '../../Icons/PowerednowRadio';
const greenBase = '#27AE60';
const blueBase = '#3498DB';
const orangeBase = '#F39C12';
const redBase = '#E74C3B';
const darkBlueBase = '#2c3e50';
const silverBase = '#e2e2e2';
const PowerednowLightTheme = createTheme(deepmerge(lightThemeSource, {
    shape: {
        borderRadius: 3,
    },
    palette: {
        mode: 'light',
        primary: {
            main: greenBase,
            dark: darken(greenBase, 0.05),
            active: darken(greenBase, 0.10),
        },
        secondary: {
            main: blueBase,
            dark: darken(blueBase, 0.05),
            active: darken(blueBase, 0.10),
        },
        warning: {
            main: orangeBase,
            dark: darken(orangeBase, 0.05),
            active: darken(orangeBase, 0.10),
        },
        error: {
            main: redBase,
            dark: darken(redBase, 0.05),
            active: darken(redBase, 0.10),
        },
        text: {
            secondary: '#5E5E5E',
        },
        radio: {
            checked: '#3498DB',
            unchecked: '#E5E0D5',
        },
        appSpecificColors: {
            greenBase,
            silverBase,
            darkBlueBase,
            muiIconButton: {
                backgroundColor: '#d2d2d2',
                hover: '#8a8a8a',
            },
            muiInputBase: {
                backgroundColor: '#f6f4f2',
                inputFocus: '#f6f4f2',
            },
        },
    },
    poweredNowTypography: {
        fontSize: {
            base: '12.8px',
            small: '10.5px',
        },
    },
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    backgroundColor: blueBase,
                    borderRadius: '5px',
                    color: '#fff',
                    position: 'relative',
                    '&:before': {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        margin: 'auto',
                        marginTop: '-30px',
                        content: '""',
                        zIndex: 1,
                        width: '40px',
                        height: '40px',
                        border: '20px solid rgba(0, 0, 0, 0)',
                        borderBottomColor: blueBase,
                    },
                },
            },
        },
        DatePicker: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiIconButton-root': {
                        padding: 0,
                        transform: 'translate(-15px)',
                        backgroundColor: theme.palette.appSpecificColors.muiIconButton.backgroundColor,
                        '&:hover': {
                            backgroundColor: theme.palette.appSpecificColors.muiIconButton.hover,
                        },
                    },
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                        position: 'initial',
                        marginBottom: '5px',
                    },
                }),
            },
        },
        DialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.appSpecificColors.darkBlueBase,
                }),
                closeButton: ({ theme }) => ({
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.common.white,
                    width: '40px',
                    height: '40px',
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                    },
                }),
            },
        },
        Switch: {
            styleOverrides: {
                root: {
                    width: '100%',
                    paddingBottom: '20px',
                    '& .MuiFormControlLabel-label': {
                        fontWeight: 600,
                    },
                },
                element: ({ theme }) => ({
                    width: 80,
                    height: 40,
                    padding: 0,
                    borderRadius: 20,
                    '& .MuiSwitch-switchBase': {
                        margin: '4px 4px 4px 0',
                        padding: 0,
                        transform: 'translateX(6px)',
                        '&.Mui-checked': {
                            transform: 'translateX(42px)',
                        },
                        '&.Mui-checked + .MuiSwitch-track': {
                            opacity: 100,
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        width: 32,
                        height: 32,
                        color: theme.palette.appSpecificColors.darkBlueBase,
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 20 / 2,
                    },
                }),
                label: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: 0,
                    width: '100%',
                },
            },
            defaultProps: {
                labelPlacement: 'start',
                disableRipple: true,
            },
        },
        TextField: {
            styleOverrides: {
                root: ({ theme }) => ({
                    width: '100%',
                    '& .MuiFormLabel-root': {
                        position: 'initial',
                        '& .MuiFormLabel-asterisk': {
                            color: 'red',
                        },
                    },
                    '& .MuiIconButton-root': {
                        transform: 'scale(0.6)',
                        backgroundColor: theme.palette.appSpecificColors.muiIconButton.backgroundColor,
                        '&:hover': {
                            backgroundColor: theme.palette.appSpecificColors.muiIconButton.hover,
                        },
                    },
                    '& .MuiInputBase-root': {
                        marginTop: theme.spacing(5),
                    },
                }),
            },
            defaultProps: {
                variant: 'standard',
                clearable: true,
                noBorder: true,
                InputLabelProps: {
                    shrink: false,
                },
            },
        },
        TimePicker: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiIconButton-root': {
                        padding: 0,
                        transform: 'translate(-15px)',
                        backgroundColor: theme.palette.appSpecificColors.muiIconButton.backgroundColor,
                        '&:hover': {
                            backgroundColor: theme.palette.appSpecificColors.muiIconButton.hover,
                        },
                    },
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                        position: 'initial',
                        marginBottom: '5px',
                    },
                }),
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => (Object.assign({}, (ownerState.variant === 'contained'
                    && {
                        color: (ownerState.color === 'warning' || ownerState.color === 'neutral') ? theme.palette.common.black : theme.palette.common.white,
                        '&:hover': {
                            color: (ownerState.color === 'warning' || ownerState.color === 'neutral') ? theme.palette.common.black : theme.palette.common.white,
                        },
                        '&:active': {
                            backgroundColor: theme.palette[ownerState.color].active,
                        },
                    }))),
            },
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiCheckbox: {
            defaultProps: {
                icon: _jsx(DoneOutline, {}),
                checkedIcon: _jsx(Done, {}),
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.text.secondary,
                }),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiFormHelperText-root': {
                        padding: '10px',
                        marginBottom: '10px',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '& .MuiCheckbox-root': {
                        border: '1px solid white',
                        padding: '5px',
                        marginRight: '5px',
                        borderRadius: '24px',
                        color: 'rgba(255, 255, 255, 0.3)',
                    },
                    '& .MuiCheckbox-root.Mui-checked': {
                        color: 'white',
                    },
                    '& .MuiRadio-root': {
                        '& .MuiSvgIcon-root': {
                            fontSize: '40px',
                        },
                    },
                    '@media (max-width:600px)': {
                        marginLeft: '0.5rem',
                        '& .MuiCheckbox-root': {
                            backgroundColor: greenBase,
                            border: 0,
                            '&:hover': {
                                backgroundColor: greenBase,
                            },
                        },
                        '& .MuiCheckbox-root.Mui-checked': {
                            backgroundColor: greenBase,
                        },
                        '& .MuiFormControlLabel-label': {
                            mixBlendMode: 'difference',
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.appSpecificColors.muiInputBase.backgroundColor,
                    '&:hover': {
                        borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                        position: 'relative',
                        fontSize: 16,
                        color: theme.palette.text.secondary,
                        width: '100%',
                        padding: '10px 12px',
                        boxSizing: 'content-box',
                        '&.MuiInput-input': {
                            '&:focus': {
                                backgroundColor: theme.palette.appSpecificColors.muiInputBase.inputFocus,
                            },
                        },
                    },
                    '&.MuiInput-root': {
                        '&::after, ::before, :hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: 'none',
                        },
                    },
                    paddingBottom: '4px',
                    marginBottom: '20px',
                }),
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    fontSize: '14.4px',
                },
            },
            defaultProps: {
                shrink: false,
            },
        },
        MuiList: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&>.MuiButtonBase-root.Mui-selected': {
                        color: 'white',
                        backgroundColor: theme.palette.appSpecificColors.greenBase,
                        '&:hover': {
                            backgroundColor: theme.palette.appSpecificColors.greenBase,
                        },
                    },
                    '&>.Mui-focusVisible.MuiButtonBase-root': {
                        color: theme.palette.primary.main,
                        backgroundColor: 'transparent',
                    },
                    '&>.Mui-focusVisible.MuiButtonBase-root.Mui-selected': {
                        color: 'white',
                        backgroundColor: lighten(theme.palette.appSpecificColors.greenBase, 0.15),
                    },
                }),
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.appSpecificColors.greenBase,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: theme.palette.appSpecificColors.greenBase,
                        },
                    },
                    '&:hover': {
                        backgroundColor: lighten(theme.palette.appSpecificColors.greenBase, 0.15),
                        color: 'white',
                    },
                }),
            },
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-focusVisible': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                icon: _jsx(PowerednowRadio, {}),
                checkedIcon: _jsx(PowerednowRadio, { checked: true }),
                disableRipple: true,
            },
        },
    },
}));
const PowerednowDarkTheme = createTheme(deepmerge(lightThemeSource, {}));
export { PowerednowLightTheme, PowerednowDarkTheme, };
